import 'leaflet/dist/leaflet.css'
import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import L from 'leaflet'
import React, { memo, useEffect, useState } from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

export interface Props {
  address?: string
  googleMapsURL?: string
  languageCode: string
  latitude?: string
  longitude?: string
  siteName?: string
  zoom?: string
}

export const Map = memo(function Map({
  address,
  googleMapsURL,
  languageCode,
  latitude,
  longitude,
  siteName,
  zoom,
}: Props) {
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: [45, 60],
        iconAnchor: [22, 60],
        html: `<svg xmlns="http://www.w3.org/2000/svg" width="44.483" height="60" viewBox="0 0 44.483 60"><path d="M162.235,59.99,172.062,42.4a22.722,22.722,0,0,0,12.413-20.172,22.241,22.241,0,1,0-44.483,0A22.724,22.724,0,0,0,152.406,42.4Zm0-48.1A10.607,10.607,0,0,1,173.1,22.75a10.862,10.862,0,1,1-21.724,0A10.607,10.607,0,0,1,162.235,11.888Z" transform="translate(-139.993 0.01)"/></svg>`,
      }),
    )
  }, [])

  return (
    <Container row wrap stretch tag="section">
      <Leaflet>
        {typeof window !== undefined && latitude && longitude ? (
          <MapContainer
            center={[Number(latitude), Number(longitude)]}
            dragging={L.Browser && L.Browser.mobile ? false : true}
            zoom={Number(zoom)}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            />
            <Marker
              icon={markerIcon}
              position={[Number(latitude), Number(longitude)]}
            />
          </MapContainer>
        ) : null}
      </Leaflet>
      <Wrapper>
        {siteName ? <SiteName>{siteName}</SiteName> : null}
        {address ? <Address>{address}</Address> : null}
        {googleMapsURL ? (
          <CTA
            label={useVocabularyData('open-the-map', languageCode)}
            URL={googleMapsURL}
            rel="noreferrer"
            target="_blank"
            variant="invert"
          />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 7.5rem auto 12.5rem;

  @media (max-width: 1023px) {
    margin: 5.625rem auto 7.5rem;
  }
`

const Leaflet = styled.div`
  width: 100%;
  padding-bottom: 35%;
  overflow: hidden;
  position: relative;

  .leaflet-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .leaflet-marker-icon {
    background: none;
    border: 0;
    cursor: default;
    svg {
      fill: ${({ theme }) => theme.colors.variants.primaryDark1};
    }
  }
  .leaflet-left {
    top: 1.5rem;
    right: 1.5rem;
    left: auto;

    .leaflet-control {
      display: flex;
      flex-direction: column;
      border: 0;
      border-radius: 0;
      margin: 0;
      a {
        width: 3rem;
        height: 3rem;
        line-height: 2.875rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        border: 0;
        border-radius: 0 !important;
        opacity: 0.6;
        position: relative;
        text-indent: -9999px;
        transition: 0.2s ease-out;
        &.leaflet-control-zoom-in,
        &.leaflet-control-zoom-out {
          &:before {
            content: '';
            width: 1.125rem;
            height: 0.125rem;
            background: ${({ theme }) => theme.colors.variants.neutralDark2};
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &.leaflet-control-zoom-in {
          &:after {
            content: '';
            width: 0.125rem;
            height: 1.125rem;
            background: ${({ theme }) => theme.colors.variants.neutralDark2};
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &.leaflet-disabled {
          pointer-events: none;
          &:before,
          &:after {
            opacity: 0.2;
          }
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .leaflet-bottom {
    display: none;
  }

  @media (max-width: 1023px) {
    padding-bottom: 50%;

    .leaflet-left {
      display: none;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 100%;
  }
`

const Wrapper = styled.div`
  display: inline-block;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: -3.75rem auto 0;
  padding: 3.75rem 6.667vw;
  position: relative;
  text-align: center;
  z-index: 2;

  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 3rem 1.875rem;
  }
`

const SiteName = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.6875rem;
  margin-bottom: 0.75rem;
`

const Address = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2rem;
`

const CTA = styled(Button)`
  margin-top: 2.5rem;
`
